<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <!-- <div v-if="mappingNotEmpty" class="modal__container"> -->
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__header-content">
              <div class="modal__title" data-v-a1b94e5c="">Add Lifting</div>
              <div class="modal__header-panel">
                <div
                  class="modal__close-button"
                  v-on:click="
                    () => this.$router.push('/')
                  "
                >
                  <div class="close-button__img"></div>
                  <span>Cancel</span>
                </div>
                <!-- <div class="modal__save-button" v-on:click="() => this.sendToQB()"></div> -->
                <div class="modal__save-button">
                  <CustomLoader v-if="sendingToQB" />
                  <div v-else class="save-button__body">
                    <div class="save-button__img"></div>
                    <span>Save</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <template v-for="(elem, index) in mapping" :key="index">
                <div
                  class="switch-bar__item"
                  v-bind:class="{
                    'switch-bar__item_active': index == selectedSwitcher,
                  }"
                  v-on:click="selectedSwitcher = index"
                  v-if="isShowTab(elem)"
                >
                  {{ elem }}
                </div>
              </template>

              <!-- <div class="switch-bar__item google-drive" v-if="mappingNotEmpty"> -->
              <div class="switch-bar__item google-drive">
                <div class="google-drive__left-img"></div>
                <a target="_blank" href="#">Google Drive</a>
              </div>

            </div>
            <div class="switcher-content">

              <div class="" v-if="selectedSwitcher == 0">
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Contract # and Type.</div>
                            <div>
                              <textarea
                                  v-model="formData.contractAndType"
                                  cols="30"
                                  rows="1"
                                  class="switcher-content__text-data-textarea"
                                  :disabled="true"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight summary</div>
                            <div>
                              <textarea
                                  v-model="formData.freightSummary"
                                  cols="30"
                                  rows="1"
                                  class="switcher-content__text-data-textarea"
                                  :disabled="true"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.vesselName"
                                  :options="sortOptionsById(tablesOptions.brxis6w8r, 6)"
                                  :searchable="true"
                                  :label="6"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Accepted</div>
                            <div>
                              <input
                                v-model="formData.accepted"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.accepted"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel name (formula)</div>
                            <div>
                              <input
                                v-model="formData.vesselNameFormula"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Nomination Time</div>
                            <div>
                              <DatePicker
                                v-model="formData.nominationTime"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Appropriation To Buyers Done</div>
                            <div>
                              <input
                                v-model="formData.appropriationToBuyersDone"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.accepted"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - DWT</div>
                            <div>
                              <input
                                v-model="formData.vesselDWT"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - LOA</div>
                            <div>
                              <input
                                v-model="formData.vesselLOA"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - Beam</div>
                            <div>
                              <input
                                v-model="formData.vesselBeam"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - IMO</div>
                            <div>
                              <input
                                v-model="formData.vesselIMO"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - Age</div>
                            <div>
                              <input
                                v-model="formData.vesselAge"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Vessel - Flag</div>
                            <div>
                              <input
                                v-model="formData.vesselFlag"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Head Owner - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.headOwnerName"
                                  :options="sortOptionsById(tablesOptions.brxixht2m, 6)"
                                  :searchable="true"
                                  :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP - Chain</div>
                            <div>
                              <textarea
                                  v-model="formData.cpChain"
                                  cols="20"
                                  rows="5"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Last Cargo</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.lastCargo"
                                  :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                  :searchable="true"
                                  :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cleanliness</div>
                            <div>
                              <input
                                v-model="formData.cleanliness"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Previous Port Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                  v-model="formData.previousPortName"
                                  :options="sortOptionsById(tablesOptions.br3isji2e, 6)"
                                  :searchable="true"
                                  :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Demurrage</div>
                            <div>
                              <input
                                v-model="formData.demurrage"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Demurrage Alert</div>
                            <div>
                              <input
                                v-model="formData.demurrageAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Vessel Specs Check</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CNF Specs Summary</div>
                            <div>
                              <input
                                v-model="formData.cnfSpecsSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cnf Alert</div>
                            <div>
                              <input
                                v-model="formData.cnfAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Vessels Specs Summary</div>
                            <div>
                              <input
                                v-model="formData.freightVesselsSpecsSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Alert</div>
                            <div>
                              <input
                                v-model="formData.freightAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Port Specs Summary</div>
                            <div>
                              <input
                                v-model="formData.dischargePortSpecsSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Alert</div>
                            <div>
                              <input
                                v-model="formData.dischargeAlert"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Arrival Itinerary</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Itinerary</div>
                            <div>
                              <textarea
                                  v-model="formData.itinerary"
                                  cols="20"
                                  rows="5"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">AIS Summary</div>
                            <div>
                              <input
                                v-model="formData.aisSummary"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETA - Load Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.etaLoadPort"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Shakey ETA</div>
                            <div>
                              <input
                                v-model="formData.shakeyEta"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.shakeyEta"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Update ETA - Load Port</div>
                            <div>
                              <input
                                v-model="formData.updateEtaLoadPort"
                                type="text"
                                class="switcher-content__data-input checkbox"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETB - Load Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.etbLoadPort"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETC - Load Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.etcLoadPort"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Update ETC - Load Port</div>
                            <div>
                              <input
                                v-model="formData.updateEtcLoadPort"
                                type="text"
                                class="switcher-content__data-input checkbox"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laycan</div>
                            <div>
                              <input
                                v-model="formData.laycan"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Sailing Itinerary</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Arrived Load 1 Timestamp</div>
                            <div>
                              <DatePicker
                                v-model="formData.arrivedLoad1Timestamp"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Completed Load 1 Timestamp</div>
                            <div>
                              <DatePicker
                                v-model="formData.completedLoad1Timestamp"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days at load</div>
                            <div>
                              <input
                                v-model="formData.daysAtLoad"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Punctuality</div>
                            <div>
                              <input
                                v-model="formData.punctuality"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETA - Discharge Port</div>
                            <div>
                              <DatePicker
                                v-model="formData.etaDischargePort"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days at discharge 1</div>
                            <div>
                              <input
                                v-model="formData.daysAtDischarge1"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing time</div>
                            <div>
                              <input
                                v-model="formData.sailingTime"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Total days (Ports + Sailing)</div>
                            <div>
                              <input
                                v-model="formData.totalDaysPortsSailing"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days at discharge 2</div>
                            <div>
                              <input
                                v-model="formData.daysAtDischarge2"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing time to 2nd disport</div>
                            <div>
                              <input
                                v-model="formData.sailingTimeTo2ndDisport"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Total days (2 disports)</div>
                            <div>
                              <input
                                v-model="formData.totalDays2Disports"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETC (latter of disport 1 /2)</div>
                            <div>
                              <input
                                v-model="formData.etcLatterOfDisport12"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">ETB (latter of 2 disports)</div>
                            <div>
                              <input
                                v-model="formData.etbLatterOf2Disports"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Agents</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Agent Load</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.agentLoad"
                                :options="sortOptionsById(tablesOptions.brxi28a76, 6)"
                                :searchable="true"
                                :label="6"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Agent Discharge</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.agentDischarge"
                                :options="sortOptionsById(tablesOptions.brxi28a76, 6)"
                                :searchable="true"
                                :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Estimated Intake & Pre-Stow</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 1</div>
                            <div>
                              <input
                                v-model="formData.estimatedIntake1"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Estimated Intake 1 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.estimatedIntake1CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">2nd Estimated Intake</div>
                            <div>
                              <input
                                v-model="formData.secondEstimatedIntake"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.secondEstimatedIntake"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Calculated Intake</div>
                            <div>
                              <input
                                v-model="formData.calculatedIntake"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stem formula</div>
                            <div>
                              <input
                                v-model="formData.stemFormula"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cnf Disch Draft Summary</div>
                            <div>
                              <input
                                v-model="formData.cnfDischDraftSummary"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 1</div>
                            <div>
                              <input
                                v-model="formData.preStowHold1"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 1 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.preStowHold1CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 2</div>
                            <div>
                              <input
                                v-model="formData.preStowHold2"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 2 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.preStowHold2CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 3</div>
                            <div>
                              <input
                                v-model="formData.preStowHold3"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 3 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.preStowHold3CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 4</div>
                            <div>
                              <input
                                v-model="formData.preStowHold4"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 4 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.preStowHold4CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 5</div>
                            <div>
                              <input
                                v-model="formData.preStowHold5"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Hold 5 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.preStowHold5CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Total</div>
                            <div>
                              <input
                                v-model="formData.preStowTotal"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre - Stow Total Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.preStowTotalCargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Pre-stow accepted</div>
                            <div>
                              <input
                                v-model="formData.preStowAccepted"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.preStowAccepted"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Stow</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 1</div>
                            <div>
                              <input
                                v-model="formData.stowHold1"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 1 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.stowHold1CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 2</div>
                            <div>
                              <input
                                v-model="formData.stowHold2"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 2 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.stowHold2CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 3</div>
                            <div>
                              <input
                                v-model="formData.stowHold3"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 3 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.stowHold3CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 4</div>
                            <div>
                              <input
                                v-model="formData.stowHold4"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 4 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.stowHold4CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 5</div>
                            <div>
                              <input
                                v-model="formData.stowHold5"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Hold 5 Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.stowHold5CargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Total (MT)</div>
                            <div>
                              <input
                                v-model="formData.stowTotalMT"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stow Total Cargo-Type - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.stowTotalCargoTypeName"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Deadfreight</div>
                            <div>
                              <input
                                v-model="formData.deadfreight"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Additional Cargo</div>
                            <div>
                              <input
                                v-model="formData.additionalCargo"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">stow_calc</div>
                            <div>
                              <input
                                v-model="formData.stowCalc"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>   
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing Draft FWD (m)</div>
                            <div>
                              <input
                                v-model="formData.sailingDraftFWDm"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Sailing Draft AFT (m)</div>
                            <div>
                              <input
                                v-model="formData.sailingDraftAFTm"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Est arrival draft FWD (m)</div>
                            <div>
                              <input
                                v-model="formData.estArrivalDraftFWDm"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Est arrival draft AFT (m)</div>
                            <div>
                              <input
                                v-model="formData.estArrivalDraftAFTm"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr> 
                    </table>
                  </div>
                </div>
              </div>
              <div class="" v-else-if="selectedSwitcher == 1">
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.cpDate"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Broker</div>
                            <div>
                              <input
                                v-model="formData.broker"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight contract #</div>
                            <div>
                              <input
                                v-model="formData.freightContract"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Owner</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.owner"
                                :options="sortOptionsById(tablesOptions.brxixht2m, 6)"
                                :searchable="true"
                                :label="6"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stem Size 1</div>
                            <div>
                              <input
                                v-model="formData.stemSize1"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Tolerance 1</div>
                            <div>
                              <input
                                v-model="formData.tolerance1"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cargo-Type Freight 1 - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.cargoTypeFreight1Name"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">2nd Freight Cargo</div>
                            <div>
                              <input
                                v-model="formData.secondFreightCargo"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.secondFreightCargo"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stem Size 2</div>
                            <div>
                              <input
                                v-model="formData.stemSize2"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Tolerance 2</div>
                            <div>
                              <input
                                v-model="formData.tolerance2"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cargo-Type Freight 2 - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.cargoTypeFreight2Name"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">3rd Freight Cargo</div>
                            <div>
                              <input
                                v-model="formData.thirdFreightCargo"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.thirdFreightCargo"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Stem Size 3</div>
                            <div>
                              <input
                                v-model="formData.stemSize3"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Tolerance 3</div>
                            <div>
                              <input
                                v-model="formData.tolerance3"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cargo-Type Freight 3 - Name</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.cargoTypeFreight3Name"
                                :options="sortOptionsById(tablesOptions.brx74qi6y, 9)"
                                :searchable="true"
                                :label="9"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Vessel</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max Dwt</div>
                            <div>
                              <input
                                v-model="formData.freightMaxDwt"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max LOA</div>
                            <div>
                              <input
                                v-model="formData.freightMaxLoa"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max Beam</div>
                            <div>
                              <input
                                v-model="formData.freightMaxBeam"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Max Age</div>
                            <div>
                              <input
                                v-model="formData.freightMaxAge"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight - OAP</div>
                            <div>
                              <textarea
                                  v-model="formData.freightOAP"
                                  cols="20"
                                  rows="10"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight - Gear</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.freightGear"
                                :options="['Gearless only', 'Geared or Gearless', 'Geared only', 'Geared with Grabs']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Load</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port formula</div>
                            <div>
                              <input
                                v-model="formData.loadPortFormula"
                                type="text"
                                class="switcher-content__data-input"
                                :disabled="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Layday</div>
                            <div>
                              <DatePicker
                                v-model="formData.layday"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Cancelling</div>
                            <div>
                              <DatePicker
                                v-model="formData.cancelling"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Potential Layday</div>
                            <div>
                              <DatePicker
                                v-model="formData.potentialLayday"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Potential Cancelling</div>
                            <div>
                              <DatePicker
                                v-model="formData.potentialCancelling"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Potential ETA</div>
                            <div>
                              <DatePicker
                                v-model="formData.potentialETA"
                                :format="(t) => formatDateTimeFunc(t)"
                                :previewFormat="(t) => formatDateTimeFunc(t)"
                                :enableTimePicker="true"
                                :value-type="valueType.date2value"  
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel</div>
                            <div>
                              <input
                                v-model="formData.loadRate1Vessel"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeLoad1Vessel"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursLoad1Vessel"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsLoad1Vessel"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Rate Choice</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.loadPort1RateChoice"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Load Options</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Base</div>
                            <div>
                              <input
                                v-model="formData.loadRate1VesselBase"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Base</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeLoad1VesselBase"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Base</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursLoad1VesselBase"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Base</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsLoad1VesselBase"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 1</div>
                            <div>
                              <input
                                v-model="formData.loadRate1VesselOption1"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 1</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeLoad1VesselOption1"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 1</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursLoad1VesselOption1"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 1</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsLoad1VesselOption1"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 1 Diff</div>
                            <div>
                              <input
                                v-model="formData.loadPort1Option1Diff"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 2</div>
                            <div>
                              <input
                                v-model="formData.loadRate1VesselOption2"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 2</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeLoad1VesselOption2"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 2</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursLoad1VesselOption2"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 2</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsLoad1VesselOption2"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 2 Diff</div>
                            <div>
                              <input
                                v-model="formData.loadPort1Option2Diff"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 3</div>
                            <div>
                              <input
                                v-model="formData.loadRate1VesselOption3"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 3</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeLoad1VesselOption3"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 3</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursLoad1VesselOption3"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 3</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsLoad1VesselOption3"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 3 Diff</div>
                            <div>
                              <input
                                v-model="formData.loadPort1Option3Diff"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 4</div>
                            <div>
                              <input
                                v-model="formData.loadRate1VesselOption4"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 4</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeLoad1VesselOption4"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 4</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursLoad1VesselOption4"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 4</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsLoad1VesselOption4"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 4 Diff</div>
                            <div>
                              <input
                                v-model="formData.loadPort1Option4Diff"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Rate 1 - Vessel - Option 5</div>
                            <div>
                              <input
                                v-model="formData.loadRate1VesselOption5"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Load 1 - Vessel - Option 5</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeLoad1VesselOption5"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Load 1 - Vessel - Option 5</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursLoad1VesselOption5"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Load 1 - Vessel - Option 5</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsLoad1VesselOption5"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Port 1 - Option 5 Diff</div>
                            <div>
                              <input
                                v-model="formData.loadPort1Option5Diff"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Discharge</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Port formula</div>
                            <div>
                              <input
                                v-model="formData.dischargePortFormula"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Disch Rate 1 - Vessel</div>
                            <div>
                              <input
                                v-model="formData.dischRate1Vessel"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Excluded Time - Disch 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.excludedTimeDisch1Vessel"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Office Hours - Disch 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.officeHoursDisch1Vessel"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Time Starts - Disch 1 - Vessel</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.timeStartsDisch1Vessel"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight guaranteed draft (m)</div>
                            <div>
                              <input
                                v-model="formData.freightGuaranteedDraftM"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Guaranteed Draft Salinity</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.freightGuaranteedDraftSalinity"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Declaration of Discharge Port</div>
                            <div>
                              <input
                                v-model="formData.declarationOfDischargePort"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Lightening Clause</div>
                            <div>
                              <textarea
                                  v-model="formData.lighteningClause"
                                  cols="20"
                                  rows="5"
                                  class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Laytime</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laytime - Shifting Time to Berth</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.laytimeShiftingTimeToBerth"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laytime - Shifting First to Second</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.laytimeShiftingFirstToSecond"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laytime - Draft Survey Time</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.laytimeDraftSurveyTime"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Time Ceases</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.freightTimeCeases"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Freight</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Rate</div>
                            <div>
                              <input
                                v-model="formData.freightRate"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Initial Freight</div>
                            <div>
                              <input
                                v-model="formData.initialFreight"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Additional Cargo Freight</div>
                            <div>
                              <input
                                v-model="formData.additionalCargoFreight"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight m2m</div>
                            <div>
                              <input
                                v-model="formData.freightM2m"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Est brokerage</div>
                            <div>
                              <input
                                v-model="formData.estBrokerage"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load Freight Differential</div>
                            <div>
                              <input
                                v-model="formData.loadFreightDifferential"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Discharge Freight Differential</div>
                            <div>
                              <input
                                v-model="formData.dischargeFreightDifferential"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Load rate Diff</div>
                            <div>
                              <input
                                v-model="formData.loadRateDiff"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Fumigation Removal Cost (-ve)</div>
                            <div>
                              <input
                                v-model="formData.fumigationRemovalCostVe"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Misc Costs</div>
                            <div>
                              <input
                                v-model="formData.miscCosts"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Max Demurrage</div>
                            <div>
                              <input
                                v-model="formData.maxDemurrage"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Brokerage</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.brokerage"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Freight Differentials</div>
                            <div>
                              <textarea
                                v-model="formData.freightDifferentials"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Ops to do:</div>
                            <div>
                              <textarea
                                v-model="formData.opsToDo"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Traffic Light</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.trafficLight"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td> 
                        <td>
                          <div>
                            <div class="switcher-content__title">Flag +</div>
                            <div>
                              <input
                                v-model="formData.FlagPlus"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Flag Notes</div>
                            <div>
                              <input
                                v-model="formData.flagNotes"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Notes</div>
                            <div>
                              <textarea
                                v-model="formData.notes"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Urgent Note</div>
                            <div>
                              <input
                                v-model="formData.urgentNote"
                                type="checkbox"
                                class="switcher-content__data-input checkbox"
                                :checked="formData.urgentNote"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Reminders</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Narrowing Days</div>
                            <div>
                              <input
                                v-model="formData.narrowingDays"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Laycan Narrowing Window</div>
                            <div>
                              <input
                                v-model="formData.laycanNarrowingWindow"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Nom Days</div>
                            <div>
                              <input
                                v-model="formData.nomDays"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Substitution Days Prior ETA</div>
                            <div>
                              <input
                                v-model="formData.substitutionDaysPriorETA"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Fixture: Declare load rate</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fixtureDeclareLoadRate"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Fixture: Declare Discharge Rate</div>
                            <div class="switcher-content__data-multiselect">
                              <Multiselect
                                v-model="formData.fixtureDeclareDischargeRate"
                                :options="['Test1', 'Test2']"
                                :searchable="true"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Draw up CP days</div>
                            <div>
                              <input
                                v-model="formData.drawUpCpDays"
                                type="text"
                                class="switcher-content__data-input"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP Signed Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.cpSignedDate"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="switcher-content__title">Days to Signature</div>
                            <div>
                              <DatePicker
                                v-model="formData.daysToSignature"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">CP Notes</div>
                            <div>
                              <textarea
                                v-model="formData.cpNotes"
                                cols="20"
                                rows="5"
                                class="switcher-content__text-data-textarea"
                              ></textarea>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
                <div class="switcher-content__sub-tab">
                  <div class="switcher-content__sub-tab-title">Tasks</div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Narrowing Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.narrowingDate"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <div class="switcher-content__title">Nomination Date</div>
                            <div>
                              <DatePicker
                                v-model="formData.nominationDate"
                                :format="(t) => formatDateFunc(t)"
                                :previewFormat="(t) => formatDateFunc(t)"
                                :enableTimePicker="false"
                                :value-type="valueType.date2value"
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="" v-else-if="selectedSwitcher == 2">
                <table class="switcher-content__sub-tab-table">
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Load Port 1</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.loadPort1"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Load Port 2</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.loadPort2"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Load Country</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.loadCountry"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <!-- <td>
                      <div>
                        <div class="switcher-content__title">Load Port formula</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            :options="loadPortFormulaOptions"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td> -->
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Way Point 1 Port Name</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.wayPoint1PortName"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Way Point 2 Port Name</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.wayPoint2PortName"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Port 1</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.dischargePort1"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Port 2</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.dischargePort2"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Port 3</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.dischargePort3"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Discharge Country</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.dischargeCountry"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Related Discharge Port</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            v-model="formData.relatedDischargePort"
                            :options="['Test1', 'Test2']"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td>
                    <!-- <td>
                      <div>
                        <div class="switcher-content__title">Discharge Port formula</div>
                        <div class="switcher-content__data-multiselect">
                          <Multiselect
                            :options="dischargePortFormulaOptions"
                            :searchable="true"
                          />
                        </div>
                      </div>
                    </td> -->
                  </tr>
                </table>
              </div>
              <div class="" v-else-if="selectedSwitcher == 3">
                <table class="switcher-content__sub-tab-table">
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Freight Invoiced</div>
                        <div>
                          <input
                            v-model="formData.freightInvoiced"
                            type="text"
                            class="switcher-content__data-input"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Due owners</div>
                        <div>
                          <input
                            v-model="formData.dueOwners"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Initial Freight Paid</div>
                        <div>
                          <input
                            v-model="formData.initialFreightPaid"
                            type="text"
                            class="switcher-content__data-input"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">IFI Check</div>
                        <div>
                          <input
                            v-model="formData.ifiCheck "
                            type="checkbox"
                            class="switcher-content__data-input checkbox"
                            :checked="formData.ifiCheck"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Initial Freight (bs rate * final stow)</div>
                        <div>
                          <input
                            v-model="formData.initialFreightBsRateFinalStow"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Initial Freight Brokerage @ 1.25%</div>
                        <div>
                          <input
                            v-model="formData.initialFreightBrokerage1point25"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Diff's Brokerage</div>
                        <div>
                          <input
                            v-model="formData.diffsBrokerage"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="switcher-content__title">Total Brokerage / estimated for TBN</div>
                        <div>
                          <input
                            v-model="formData.totalBrokerageEstimatedForTBN"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Final Freight Formula</div>
                        <div>
                          <textarea
                            v-model="formData.finalFreightFormula"
                            cols="20"
                            rows="5"
                            class="switcher-content__text-data-textarea"
                            :disabled="true"
                          ></textarea>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">IFI Ttl</div>
                        <div>
                          <input
                            v-model="formData.ifiTtl"
                            type="text"
                            class="switcher-content__data-input"
                            :disabled="true"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">FFI Paid?</div>
                        <div>
                          <input
                            v-model="formData.ffiPaid"
                            type="checkbox"
                            class="switcher-content__data-input checkbox"
                            :checked="formData.ffiPaid"
                          />
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div>
                        <div class="switcher-content__title">Acc Comments</div>
                        <div>
                          <textarea
                            v-model="formData.accComments"
                            cols="20"
                            rows="5"
                            class="switcher-content__text-data-textarea"
                          ></textarea>
                        </div>
                      </div>
                    </td>
                  </tr>

                </table>
              </div>

              <div class="" v-else-if="selectedSwitcher == 4">
                <div class="switcher-content__sub-tab-title">Facts</div>
                <div>
                  <table class="switcher-content__sub-tab-table">

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Pre-Advise Expiry</div>
                          <div>
                            <input
                              v-model="formData.preAdviseExpiry"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Layday</div>
                          <div>
                            <input
                              v-model="formData.laytimeLayday"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Dem / Des</div>
                          <div>
                            <input
                              v-model="formData.demDes"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Load Summary</div>
                          <div>
                            <textarea
                              v-model="formData.loadSummary"
                              cols="20"
                              rows="5"
                              class="switcher-content__text-data-textarea"
                            ></textarea>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Laytime bss 10x</div>
                          <div>
                            <input
                              v-model="formData.laytimeBss10x"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Disch Summary</div>
                          <div>
                            <textarea
                              v-model="formData.dischSummary"
                              cols="20"
                              rows="5"
                              class="switcher-content__text-data-textarea"
                            ></textarea>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="switcher-content__sub-tab-title">Vessel Laytime</div>
                <div>
                  <table class="switcher-content__sub-tab-table">

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Load 1:</div>
                          <div>
                            <input
                              v-model="formData.load1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.LoadCharterers"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.LoadModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Owners</div>
                          <div>
                            <input
                              v-model="formData.LoadOwners"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.LoadModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.LoadResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Disch 1:</div>
                          <div>
                            <input
                              v-model="formData.disch1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.dischCharterers"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.dischModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Owners</div>
                          <div>
                            <input
                              v-model="formData.dischOwners"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.dischModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.dischResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">CNF spread: Owrs vs Buyers</div>
                          <div>
                            <input
                              v-model="formData.cnfSpreadOwrsVsBuyers"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="switcher-content__sub-tab-title">Commodity Laytime</div>
                <div>
                  <table class="switcher-content__sub-tab-table">

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Load 1:</div>
                          <div>
                            <input
                              v-model="formData.load1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.LoadCharterers"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.LoadModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Shippers</div>
                          <div>
                            <input
                              v-model="formData.shippers"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.LoadModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.LoadResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Disch 1:</div>
                          <div>
                            <input
                              v-model="formData.disch1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Charterers</div>
                          <div>
                            <input
                              v-model="formData.dischCharterers"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.dischModified1"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Shippers</div>
                          <div>
                            <input
                              v-model="formData.shippers"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Modified</div>
                          <div>
                            <input
                              v-model="formData.dischModified2"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="switcher-content__title">Result</div>
                          <div>
                            <input
                              v-model="formData.dischResult"
                              type="text"
                              class="switcher-content__data-input"
                              :disabled="true"
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div class="switcher-content__title">Laytime notes</div>
                          <div>
                            <textarea
                              v-model="formData.dischSummary"
                              cols="20"
                              rows="5"
                              class="switcher-content__text-data-textarea"
                            ></textarea>
                          </div>
                        </div>
                      </td>
                    </tr>
                    </table>
                </div>
              </div>
              <div class="" v-else-if="selectedSwitcher == 5">
                <table class="switcher-content__sub-tab-table">
                  <tr>
                    <td>
                      <div>
                        <div class="field-title"></div>
                        <div class="url-btn" style="background-color: rgb(222, 236, 252); color: black">
                          <div>Add Sale: Cnf Contract</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="field-title"></div>
                        <div class="url-btn" style="background-color: rgb(185, 206, 235); color: black">
                          <div>Add Sale: Fob Contract</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div class="field-title"></div>
                        <div class="url-btn" style="background-color: rgb(195, 180, 210); color: black">
                          <div>Add Purchase: Cnf Contract</div>
                        </div>
                      </div>
                    </td>
                    <td>
                     <div>
                       <div class="field-title"></div>
                       <div class="url-btn" style="background-color: rgb(155, 180, 96); color: black">
                         <div>Add Purchase: Fob Contract</div>
                       </div>
                     </div>
                    </td>
                  </tr>
                </table>

              </div>
            </div>
       
          </div>

          <div class="modal__footer">
            
          </div>
        </div>
        <!-- <div v-else  class="modal__container-loader">
          <CustomLoader/>
        </div> -->
      </div>
    </div>

    <AddDocumentTypeCnfPopUp
      v-if="showModalAddDocumentTypeCnf"
      :open="showModalAddDocumentTypeCnf"
      @showStatus="showStatusAddDocumentTypeCnfPopUp"
      :rid="this.rid"
      :type="this.type"
    />

    <AddDocumentTypeFobPopUp
      v-if="showModalAddDocumentTypeFob"
      :open="showModalAddDocumentTypeFob"
      @showStatus="showStatusAddDocumentTypeFobPopUp"
      :rid="this.rid"
      :type="this.type"
    />
  </div>
</template>

<script>
import Multiselect from "@vueform/multiselect";
import moment from "moment";
import CustomLoader from "@/components/loader/CustomLoader.vue";
import AddDocumentTypeCnfPopUp from "@/components/pop-up/AddDocumentTypeCnfPopUpNew.vue";
import AddDocumentTypeFobPopUp from "@/components/pop-up/AddDocumentTypeFobPopUpNew.vue";
// import QbDocumentReport from "@/components/report/QbDocumentReport.vue";
// import QbDocumentReportAll from "@/components/report/QbDocumentReportAll.vue";
// import { reactive } from "@vue/reactivity";
import { reactive } from "vue";
// import QCurrencyInput from "@/components/QCurrencyInput.vue";
// import PercentInput from "@/components/PercentInput.vue";
// import { GoogleMap, Marker, Polyline } from "vue3-google-map"; //

import { mapState } from "vuex";

import liftingFieldsModel  from "@/model/liftingFields";

export default {
  name: "CreateLiftingRecord",
  components: {
    Multiselect,
    CustomLoader,
    AddDocumentTypeCnfPopUp,
    AddDocumentTypeFobPopUp,
    // QbDocumentReport,
    // QCurrencyInput,
    // QbDocumentReportAll,
    // PercentInput,
    // GoogleMap,
    // Marker,
    // Polyline,
  },
  props: {
    // table: String, // delete
    // selectedReport: String, // delete
    open: Boolean, // delete
    rid: Number,
  },
  emits: ["showStatus", "updateRecord"],
  setup() {
    let dataForDropDownFields = reactive({
      bsghxwya8: {},
      br6kf4scx: {},
      br3isn8nb: {},
      br3isji2e: {},
      brx74qi6y: {},
      brxi28a76: {},
      bsud3n4i3: {},
      bsuecfn4c: {},
    });
    return {
      dataForDropDownFields,
    };
  },
  data: function () {
    return {
      formData: {
        vesselName: "",
        nominationTime: "",
        freightSummary: "",
        contractAndType: "",
        vesselNameFormula: "",
        accepted: false,
        appropriationToBuyersDone: false,
        vesselDWT: "",
        vesselLOA: "",
        vesselBeam: "",
        vesselIMO: "",
        vesselAge: "",
        vesselFlag: "",
        headOwnerName: "",
        cpChain: "",
        lastCargo: "",
        cleanliness: "",
        previousPortName: "",
        demurrageAlert: "",    // i am not sure
        cnfSpecsSummary: "",
        cnfAlert: "",
        freightVesselsSpecsSummary: "",
        freightAlert: "",
        dischargePortSpecsSummary: "",
        dischargeAlert: "",
        etaLoadPort: "",
        shakeyEta: "",
        itinerary: "",
        updateEtaLoadPort: "",
        etbLoadPort: "",
        etcLoadPort: "",
        updateEtcLoadPort: "",
        laycan: "",
        arrivedLoad1Timestamp: "",
        completedLoad1Timestamp: "",
        daysAtLoad: "",
        punctuality: "",
        etaDischargePort: "",
        daysAtDischarge1: "",
        sailingTime: "",
        totalDaysPortsSailing: "",
        daysAtDischarge2: "",
        sailingTimeTo2ndDisport: "",
        totalDays2Disports: "",
        etcLatterOfDisport12: "",
        etbLatterOf2Disports: "",
        agentLoad: "",
        agentDischarge: "",
        estimatedIntake1: "",
        estimatedIntake1CargoTypeName: "",
        secondEstimatedIntake: "",
        calculatedIntake: "",
        stemFormula: "",
        cnfDischDraftSummary: "",
        preStowHold1: "",
        preStowHold1CargoTypeName: "",
        preStowHold2: "",
        preStowHold2CargoTypeName: "",
        preStowHold3: "",
        preStowHold3CargoTypeName: "",
        preStowHold4: "",
        preStowHold4CargoTypeName: "",
        preStowHold5: "",
        preStowHold5CargoTypeName: "",
        preStowTotal: "",
        preStowTotalCargoTypeName: "",
        preStowAccepted: "",
        stowHold1: "",
        stowHold1CargoTypeName: "",
        stowHold2: "",
        stowHold2CargoTypeName: "",
        stowHold3: "",
        stowHold3CargoTypeName: "",
        stowHold4: "",
        stowHold4CargoTypeName: "",
        stowHold5: "",
        stowHold5CargoTypeName: "",
        stowTotalMT: "",
        stowTotalCargoTypeName: "",
        deadfreight: "",
        additionalCargo: "",
        stowCalc: "",
        sailingDraftFWDm: "",
        sailingDraftAFTm: "",
        estArrivalDraftFWDm: "",
        estArrivalDraftAFTm: "",

        // Freight Fixtures
        cpDate: "",
        broker: "",
        freightContract: "",
        owner: "",
        stemSize1: "",
        tolerance1: "",
        cargoTypeFreight1Name: "",
        secondFreightCargo: "",
        stemSize2: "",
        tolerance2: "",
        cargoTypeFreight2Name: "",
        thirdFreightCargo: "",
        stemSize3: "",
        tolerance3: "",
        cargoTypeFreight3Name: "",
        freightMaxDwt: "",
        freightMaxLoa: "",
        freightMaxBeam: "",
        freightMaxAge: "",
        freightOAP: "",
        freightGear: "",
        loadPortFormula: "",
        layday: "",
        cancelling: "",
        potentialLayday: "",
        potentialCancelling: "",
        potentialETA: "",
        loadRate1Vessel: "",
        excludedTimeLoad1Vessel: "",
        officeHoursLoad1Vessel: "",
        timeStartsLoad1Vessel: "",
        loadPort1RateChoice: "",
        loadRate1VesselBase: "",
        excludedTimeLoad1VesselBase: "",
        officeHoursLoad1VesselBase: "",
        timeStartsLoad1VesselBase: "",
        loadRate1VesselOption1: "",
        excludedTimeLoad1VesselOption1: "",
        officeHoursLoad1VesselOption1: "",
        timeStartsLoad1VesselOption1: "",
        loadPort1Option1Diff: "",
        loadRate1VesselOption2: "",
        excludedTimeLoad1VesselOption2: "",
        officeHoursLoad1VesselOption2: "",
        timeStartsLoad1VesselOption2: "",
        loadPort1Option2Diff: "",
        loadRate1VesselOption3: "",
        excludedTimeLoad1VesselOption3: "",
        officeHoursLoad1VesselOption3: "",
        timeStartsLoad1VesselOption3: "",
        loadPort1Option3Diff: "",
        loadRate1VesselOption4: "",
        excludedTimeLoad1VesselOption4: "",
        officeHoursLoad1VesselOption4: "",
        timeStartsLoad1VesselOption4: "",
        loadPort1Option4Diff: "",
        loadRate1VesselOption5: "",
        excludedTimeLoad1VesselOption5: "",
        officeHoursLoad1VesselOption5: "",
        timeStartsLoad1VesselOption5: "",
        loadPort1Option5Diff: "",
        dischargePortFormula: "",
        dischRate1Vessel: "",
        excludedTimeDisch1Vessel: "",
        officeHoursDisch1Vessel: "",
        timeStartsDisch1Vessel: "",
        freightGuaranteedDraftM: "",
        freightGuaranteedDraftSalinity: "",
        declarationOfDischargePort: "",
        lighteningClause: "",
        laytimeShiftingTimeToBerth: "",
        laytimeShiftingFirstToSecond: "",
        laytimeDraftSurveyTime: "",
        freightTimeCeases: "",
        freightRate: "",
        initialFreight: "",
        additionalCargoFreight: "",
        freightM2m: "",
        estBrokerage: "",
        loadFreightDifferential: "",
        dischargeFreightDifferential: "",
        loadRateDiff: "",
        fumigationRemovalCostVe: "",
        miscCosts: "",
        maxDemurrage: "",
        brokerage: "",
        freightDifferentials: "",
        opsToDo: "",
        trafficLight: "",
        FlagPlus: "",          //  
        flagNotes: "",
        notes: "",
        urgentNote: "",
        narrowingDays: "",
        laycanNarrowingWindow: "",
        nomDays: "",
        substitutionDaysPriorETA: "",
        fixtureDeclareLoadRate: "",
        fixtureDeclareDischargeRate: "",
        cpSignedDate: "",
        daysToSignature: "",
        cpNotes: "",
        narrowingDate: "",
        nominationDate: "",

        // Ports
        loadPort1: "",
        loadPort2: "",
        loadCountry: "",
        wayPoint1PortName: "",
        wayPoint2PortName: "",
        dischargePort1: "",
        dischargePort2: "",
        dischargePort3: "",
        dischargeCountry: "",
        relatedDischargePort: "",

        // Accounting
        freightInvoiced: "",
        dueOwners: "",
        initialFreightPaid: "",
        ifiCheck: "",
        initialFreightBsRateFinalStow: "",
        initialFreightBrokerage1point25: "",
        diffsBrokerage: "",
        totalBrokerageEstimatedForTBN: "",
        finalFreightFormula: "",
        ifiTtl: "",
        ffiPaid: "",
        accComments: "",

        // Laytime
        preAdviseExpiry: "",
        laytimeLayday: "",
        demDes: "",
        loadSummary: "",
        laytimeBss10x: "",
        dischSummary: "",
        load1: "",
        LoadCharterers: "",
        LoadModified1: "",
        LoadOwners: "",
        LoadModified2: "",
        LoadResult: "",
        disch1: "",
        dischCharterers: "",
        dischModified1: "",
        dischOwners: "",
        dischModified2: "",
        dischResult: "",
        cnfSpreadOwrsVsBuyers: "",

      },

      ttttt: liftingFieldsModel.fieldsMap,
      testModel: [],
      testLabel: "test label",

      documentReportAllModel: [],
      documentReportAllModelOptions: [],
      documentReportAllModelOptions2: [
        {name: "test", rid: 1},
        {name: "test2", rid: 2},
      ],
      filterApplying: false,

      clistForTables: {
        brxis6w8r: [3, 6],
        brxixht2m: [3, 6],
        brx74qi6y: [3, 9],
        brxi28a76: [3, 6],
        br3isji2e: [3, 6],
        br3isn8nb: [3, 6],
      },
      tablesOptions: {
        brxis6w8r: [],
        brxixht2m: [],
        brx74qi6y: [],
        brxi28a76: [],
        br3isji2e: [],
        br3isn8nb: [],
      },
      reports: [],
      modalName: "Lifting Summary", // +  # " + this.rid,
      allFields: [],
      fields: [],
      masterTableId: "brxisk55z",

      sendingToQB: false,

      selectedSwitcher: 0,

      showModalAddDocumentTypeCnf: false,
      showModalAddDocumentTypeFob: false,

      type: "",

      mapping: ["Nomination", "Freight Fixtures", "Ports", "Accounting", "Laytime", "Commodity Contract"],

      shipPaths: [],
    };
  },
  mounted() {
    this.getDataTaskFields();
    this.getRelData();
  },
  updated() {
    let qbUpdateEtaButton = this.$el.querySelectorAll(
      ".qb-update-eta-button .Vibrant"
    );
    qbUpdateEtaButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-eta-button");
        console.log(this);
        this.updateETALoadPort(this.rid);
        return false;
      };
    });

    let qbUpdateEtcButton = this.$el.querySelectorAll(
      ".qb-update-etc-button .Vibrant"
    );
    qbUpdateEtcButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-etc-button");
        console.log(this);
        return false;
      };
    });

    let qbUpdateCompleteCurrentTaskButton = this.$el.querySelectorAll(
      ".qb-update-complete-current-task-button .Vibrant"
    );
    qbUpdateCompleteCurrentTaskButton.forEach((el) => {
      el.onclick = () => {
        console.log("test qb-update-complete-current-task-button");
        console.log(this);
        return false;
      };
    });

    let allContracts = document.querySelectorAll(".rich-text__484 a");
    allContracts.forEach((el) => {
      el.onclick = () => {
        return false;
      };
    });

    var textarea = document.querySelectorAll(
      ".switcher-content__text-data-textarea"
    );
    textarea.forEach((el) => {
      console.log("WWWWWWWW");
      console.dir(el);
      console.log(el.value);
      console.log(el.scrollTop);
      console.log(el.scrollHeight);
      console.log(el.scrollWidth);

      el.style.height = 0;

      el.dataset.scrollTop = el.scrollTop;
      el.dataset.scrollHeight = el.scrollHeight;
      el.dataset.scrollWidth = el.scrollWidth;

      if (!el.dataset.newHeight) {
        el.dataset.newHeight = el.scrollHeight + 5 + "px";
      }
      if (!el.dataset.newWidth) {
        el.dataset.newWidth = el.scrollWidth + 20 + "px";
      }
      el.style.height = el.dataset.newHeight;
      el.style.width = el.dataset.newWidth;
      // }
    });
  },
  computed: {
    ...mapState({
       allHolidayFields: state => state.holidays.holidayRecords,
       holidayClist: state => state.holidays.holidayClist,
       holidayReportData: state => state.holidays.holidayReportData,
    }),

    mappingNotEmpty() {
      if (this.allFields.length == 0) return false;
      return true;
    },
    objAllFields() {
      let res = {};
      this.allFields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },
    valueType() {
      return {
        value2date: (value) => {
          console.log("value2date", value);

          return moment(value).format("LL");
        },
        date2value: (date) => {
          console.log("date2value", date);

          return moment(date).format("LL");
        },
      };
    },

  },
  watch: {

  },
  methods: {

    sortOptionsById(options, id) {
      const sorter = (a, b) => {
        const nameA = a[id].toUpperCase();
        const nameB = b[id].toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        return 0;
      };
      return options.sort(sorter);
    },

    async getDataForDropDownFieldsFromQB(table, clist) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          table: table,
          clist: clist,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/table/" + table + "/records",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          this.dataForDropDownFields[table] = data;
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    getDataForDropDownField(table, fid) {
      console.log("getDataForDropDownField table:" + table + " fid:" + fid);
      let result = [];
      if (Object.hasOwnProperty.call(this.dataForDropDownFields, table)) {
        const data = this.dataForDropDownFields[table];
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key)) {
            const record = data[key];
            for (const fieldId in record) {
              if (Object.hasOwnProperty.call(record, fieldId)) {
                const element = record[fieldId];
                if (fieldId == fid) {
                  result.push(element["value"]);
                }
              }
            }
          }
        }
      }
      return result;
    },

    async getRelData() {
      for (const key in this.clistForTables) {
        if (Object.hasOwnProperty.call(this.clistForTables, key)) {
          this.getRelDataQB(key, this.clistForTables[key]);
        }
      }
    },
    async getRelDataQB(tableId, clist) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          clist: clist,
          token: localStorage.token,
        }),
      };
      console.log(requestOptions);

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/table/" + tableId + "/records",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          let data = result["body"]["data"];
          // let data = result["body"];
          console.log(data);
          let newRes = [];
          for (const key in data) {
            if (Object.hasOwnProperty.call(data, key)) {
              const element = data[key];

              let tmp = {};
              for (const k in element) {
                if (Object.hasOwnProperty.call(element, k)) {
                  if (k == 3) {
                    tmp["value"] = element[k].value;
                  } else {
                    tmp[k] = element[k].value;
                  }
                }
              }
              newRes.push(tmp);
            }
          }

          this.tablesOptions[tableId] = newRes;
          console.log("dev1", this.tablesOptions.brxis6w8r)
          console.log("dev2", this.tablesOptions.brxixht2m)
          console.log("dev3", this.tablesOptions.brx74qi6y)
          console.log("dev4", this.tablesOptions.brxi28a76)
          console.log("dev5", this.tablesOptions.br3isji2e)
          console.log("dev6", this.tablesOptions.br3isn8nb)

          /*
          :options="sortOptionsById(tablesOptions.brxi28a76, 6)"
          :searchable="true"
          :label="6"
          */
          
        })
        .catch((error) => {
          console.log("error", error);
          this.gettingReport = false;
        });
    },
  
    isShowTab(value) {
      // if(this.fields.length == 0) return true;
      // "Nomination", "Freight Fixtures", "Ports", "Accounting", "Laytime", "Commodity Contract", "Dates", "", ""

      switch (value.id) {
        case 1:
          if (
            this.fields[42]["value"] == "1. Short" ||
            this.fields[42]["value"] == "2. TBN"
          ) {
            // 1 4
            return false;
          } else {
            return true;
          }
          // break;
        case 4:
          if (
            this.fields[42]["value"] == "1. Short" ||
            this.fields[42]["value"] == "2. TBN"
          ) {
            // 1 4
            return false;
          } else {
            return true;
          }
          // break;
        default:
          break;
      }
      if(value.show == false) return false;

      return true;
    },

    formatDateFunc(date, a) {
      console.log(a);
      if (date) {
        return moment(date).format("MM-DD-YYYY"); //.tz("US/Pacific")
      } else {
        return "";
      }
    },

    formatDateTimeFunc(date) {
      if (date) {
        return (
          moment(date)
            // .tz("US/Pacific")
            .format("MM-DD-yyyy h:mm a")
        ); // America/New_York
      } else {
        return "";
      }
    },


    getObjArrWithIdKey(fields) {
      let res = {};
      // this.fields.forEach((val) => {
      //   res[val["id"]] = val;
      // });
      fields.forEach((val) => {
        res[val["id"]] = val;
      });
      return res;
    },

    /**
     * For open pop-up Document
     *  */ 
    openPopUpForAddDocumentTypeCnf(type) {
      this.type = type;
      this.showModalAddDocumentTypeCnf = true;
    },
    openPopUpForAddDocumentTypeFob(type) {
      this.type = type;
      this.showModalAddDocumentTypeFob = true;
    },
    showStatusAddDocumentTypeCnfPopUp(status) {
      this.showModalAddDocumentTypeCnf = status;
    },
    showStatusAddDocumentTypeFobPopUp(status) {
      this.showModalAddDocumentTypeFob = status;
    },

    getDataTaskFields() {
      var requestOptions = {
        method: "GET",
        redirect: "follow",
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN +
          // "http://127.0.0.1:8000/api" +
          "/v1/qb/table/" +
          this.masterTableId +
          "/info",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          this.allFields = result["body"]["allFields"];
          console.log(this.objAllFields[3]);
        })
        .catch((error) => console.log("error", error));
    },

    /**
     * Save all data to quickbase
     */
    async saveDataForQB(params) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(params), // to, data
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/records/save",
        // process.env.VUE_APP_API_DOMAIN1 + "/v1/qb/records/save",
        // "http://127.0.0.1:8000/v1/qb/records/save",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          this.$router.push('/');
          this.$emit("showStatus", false);
          this.$emit("updateRecord", this.rid);
          
          // this.clients = result;
        })
        .catch((error) => {
          this.sendingToQB = false;
          console.log("error", error);
        });
    },

    updateETALoadPort(rid) {
      var requestOptions = {
        method: "POST",
        redirect: "follow",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: localStorage.token,
        }),
      };

      fetch(
        process.env.VUE_APP_API_DOMAIN + "/v1/qb/button/eta/record/" + rid,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    },

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* * {
  box-sizing: border-box;
} */
* {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
}

.switcher-content * {
  font-size: 14px;
}
.tables-panel {
  max-width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  padding-top: 20px;
}
.tables-items {
  display: flex;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.modal {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
}

.modal__mask {
  display: flex;
  flex-grow: 1;
  overflow-y: hidden;
  flex-direction: column;
}

.modal__wrapper {
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.modal__container {
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal__container::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.modal__container::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.modal__container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.modal__container-loader {
  overflow-y: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

@media screen and (max-width: 1680px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    /* width: 1070px; */
  }
}

@media screen and (max-width: 1200px) {
  .modal__container {
    /* overflow-y: scroll; */
    /* width: 300px; */
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    width: 100%;
    /* max-width: 800px; */
  }
}

.modal__header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal__header {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  /* border-bottom: 1px solid #d3dfed; */
  background: #F6FAFF;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  flex: 0 0 auto;
}

.modal__header-content {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  align-items: center;
}

.modal__header-panel {
  display: flex;
}

.modal__title {
  padding: 5px 20px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  color: #2d3757;
  max-width: 70%;
  justify-items: center;
  text-align: left;
  flex-grow: 1;
}

.modal__close {
  position: absolute;
  top: 12px;
  right: 30px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  background: url(@/assets/close.svg) no-repeat center center;
}

.modal__body {
  flex: 1 1 auto;
  overflow-y: auto;
  flex-direction: column;
  display: flex;
}

.modal__default-button {
  float: right;
}

.modal__footer {
  display: flex;
  justify-content: space-between;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  color: #2d3757;
  padding-left: 30px;
  padding-right: 30px;
  flex: 0 0 auto;
}

.modal__close-button {
  border: 1px solid #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  margin-right: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.modal__close-button:hover {
  background-color: #50629b;
  color: white;
}

.modal__close-button:hover .close-button__img {
  filter: invert(100%) brightness(1000%);
}

.close-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-close.svg) no-repeat center center;
  margin-right: 10px;
}

.modal__save-button {
  background: #2d3757;
  border-radius: 2px;
  padding: 14px 28px;
  color: #ffffff;
  margin-left: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 58px;
}

.modal__save-button:hover {
  background-color: #50629b;
  outline: none;
}

.save-button__body {
  display: flex;
  align-items: center;
}

.save-button__img {
  width: 16px;
  height: 16px;
  background: url(@/assets/btn-save.svg) no-repeat center center;
  margin-right: 10px;
}

.preloader {
  display: flex;
  justify-content: center;
}

.report-body::-webkit-scrollbar {
  width: 5px;
  height: 10px;
  background-color: #f9f9fd;
}

.report-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.report-body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.item-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 5px;
}

.item-modal__title {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #2d3757;
}

.item-modal__img {
  margin-left: 12px;
  height: 20px;
  width: 20px;
  background: url(@/assets/share.svg) no-repeat center center;
}

.switch-bar {
  display: flex;
  flex: 0 0 auto;
  background: #F6FAFF;
  padding: 0 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #D3DFED;
}

.switch-bar__item {
  /* flex: 1 1 auto; */
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  cursor: pointer;
  margin-right: 15px;
  border: 1px solid transparent;
}

.switch-bar__item:hover {
  background: #ffffff;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switch-bar__item_active {
  /* border-bottom: 2px solid #2d3757; */
  
  background: #D3DFED;
  border: 1px solid #5D88BE;
  border-radius: 2px;
}

.switcher-content {
  flex: 1 1 auto;
  max-height: 100%;
  overflow-y: auto;
  padding-left: 30px;
  padding-right: 30px;
}

.switcher-content::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #f9f9fd;
}

.switcher-content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #2d375744;
}

.switcher-content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 20px;
  /* border: 3px solid #000; */
  background-color: #e3e9f1;
}

.switcher-content__choose {
  /* display: flex; */
  /* flex-wrap: wrap; */
}

.switcher-content__sub-tab-body {
  /* display: flex; */
  /* flex-wrap: wrap; */
  /* align-items: end; */
  /* overflow: auto; */
  /* overflow-x: auto; */
}

.disabled {
  background: #eee;
}

.switcher-content__sub-tab-title {
  color: #333;
  font-size: 18px;
  font-weight: bold;
  border-bottom: #2d3757 1px solid;
}

.switcher-content__item {
  box-sizing: border-box;
  padding: 20px 20px;
  width: 50%;
  min-width: 100px;
}

.switcher-content__item_100 {
  box-sizing: border-box;
  width: 100%;
  padding: 20px 20px;
}

.switcher-content__item_25 {
  box-sizing: border-box;
  width: 25%;
  padding: 20px 20px;
}

.switcher-content__title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;
}

.field-title {
  padding: 8px 0;
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  color: #6c6f7d;

  display: flex;
  align-items: center;
}

.field-title__btn-log {
  cursor: pointer;
  margin-right: 5px;
  border-radius: 50px;
  background:  #2d3757;
  color: #fff;
  padding: 2px 7px;
}

.switcher-content__data-input {
  border: 1px solid #d3dfed;
  border-radius: 2px;
  padding: 12px;
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__data-input.checkbox {
  width: auto;
}

.switcher-content__text-data-textarea {
  min-width: 240px;
  border: 1px solid #d3dfed;
  border-radius: 2px;
  resize: none;
}

.switcher-content__text-data-textarea {
  /* box-sizing: content-box; */
  min-width: 240px;
  max-height: 538px;
  border: 1px solid #d3dfed;
  border-radius: 2px;
  display: flex;
  box-sizing: border-box;
  vertical-align: bottom;
  /* box-sizing: initial; */
  /* display: table-column; */
  padding: 10px;
  /* word-wrap: break-word; */
  white-space: pre;
  /* padding-bottom: 0; */
  /* width: 100%; */
  font-size: 16px;
  font-family: "Lato";
}

.switcher-content__text-data-textarea.disabled {
  outline: none;
  /* border: none; */
  /* border-left: 1px solid #777; */
  /* border-right: 1px solid #777; */
  /* border-bottom: 1px solid #777; */
  /* margin: 0; */
  /* padding: 0; */
  /* position: absolute; */
  /* top: 40px; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0;  */
  /* width:-moz-availabel; height:100%;  */
  resize: none;
}

.url-btn {
  display: block;
  border: 1px solid #2d3757;
  background: #2d375715;
  text-decoration: none;
  border-radius: 5px;
  padding: 5px 15px;
  cursor: pointer;
  text-align: center;
}
.url-btn:hover {
  color: #fff;
  background: #333;
}

.switcher-content__sub-tab-table {
  /* width: 100%; */
}

.switcher-content__sub-tab-table td {
  padding: 5px;
  border: 5px solid transparent;
  vertical-align: top;
}

.switcher-content__sub-tab-table td .field-title {
  box-sizing: border-box;
  max-width: 100%;
  position: relative;
}


.switcher-content__sub-tab-table td .switcher-content__data-input {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 240px;
  max-width: 240px;
}

.switcher-content__sub-tab-table td .switcher-content__data-multiselect-document-filter {
  box-sizing: border-box;
  /* max-width: 100%; */
  width: 100%;
  max-width: 320px;
}


.switcher-content__sub-tab-table td .switcher-content__data-input.checkbox {
  width: auto;
}

.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}


.btn-log {
}

.btn-log__body {
  display: none;
  width: max-content;
  position: absolute;
  background: #2d3757;
  color: #fff;
  z-index: 5;
  padding: 5px;
  left: 0;
  bottom: 30px;
  border-radius: 2px;
  box-shadow: -1px -1px 10px 0px #2d375780;
  border: 1px solid #d3dfed;
}

.btn-log:hover .btn-log__body {
  display: block;
}

</style>

<style>
.rich-text__484 div a {
  padding: 8px 8px;
  text-decoration: none;
  /* color: #6c6f7d; */

  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #2d3757;
  cursor: default;
}

.dp-custom-input {
  font-size: 14px !important;
  width: auto !important;
}
.v-toolbar {
  z-index: 20 !important;
}
.multiselect--active {
  z-index: 20 !important;
}
.multiselect__content-wrapper {
  z-index: 20 !important;
}

.btn-apply {
   display: flex;
  margin-left: 20px;
  /* margin-top: 1px; */
}

.btn-apply__body {
  /* background: rgb(106, 141, 255); */
  color: #fff;
  /* font-size: 1.25em; */
  padding: 10px 30px;
  margin-right: 20px;
  cursor: pointer;

  background: #2d3757;
  border-radius: 2px;

  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  /* line-height: 19px; */

  color: #ffffff;
}

.report-table__item-text-multiple-choice-wrap {
  min-width: 240px;
}

.document-report-wrap {
  /* max-width: 1185px; */
  /* width: 1185px; */
}

/* .report-body {
  width: 1100px;
}

@media screen and (max-width: 1200px) {
  .report-body {
    width: 700px;
  }
} */


.google-drive {
  display: flex;
  align-items: center;
}

.google-drive__left-img {
  width: 16px;
  height: 16px;
  background: url(@/assets/link.svg) no-repeat center center;
  margin-right: 8px;
}

</style>

<style scoped>
.dp__input_wrap .dp__input {
  font-size: 14px;
  padding: 8px 12px;
  padding-left: 35px;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}

.multiselect {
  height: 43px !important;
  border: 1px solid #d3dfed !important;
  border-radius: 2px !important;
}

</style>

<style scoped src="@vueform/multiselect/themes/default.css"></style>